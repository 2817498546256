<template>
    <div   >
        <div style=" background:white; padding-top:2px">
            <div style="background:#808695;font-size:14px;padding-left:5px;color:#fff;font-weight:600;line-height:30px;height:32px">
                <i class="iconfont icon-xuanze1" style="margin-right:5px"></i> 提示：只引用单据状态为“已付款” 或 “待发货” 的销售订单
            </div>
            <div style="line-height:30px;height:35px;margin-top:5px">
                <el-button type="primary" size="mini" icon="iconfont icon-yiqueren-xianxing" @click="selSure" style=" height:30px; margin-right:20px;">确定选择</el-button> 
                <span style=" margin-left:20px;font-size:12px;margin-right:10px;">字段查询</span>
                <el-select v-model="selparam" size="small" placeholder="请选择列名"   style="width:120px; " >
                    <span v-for="item in ordCol" :key='item.key'>
                        <el-option :label="item.title" :value="item.key" v-if="item.thide=='N'"></el-option>
                    </span>
                </el-select>
                <el-input clearable :placeholder="input_hold" v-model="txtparam" @keyup.enter.native="stfgChange" size="small" style="width:330px; height:30px; ">
                    <el-select v-model="oper" slot="prepend"   style="width:110px; " >
                        <span v-for="item in operArr" :key='item.IDSEQ'>
                            <el-option :label="item.CNAME" :value="item.SUBNO"></el-option>
                        </span>
                    </el-select>
                    <el-button  slot="append" icon="el-icon-search" @click="stfgChange" ></el-button>
                </el-input>
                <el-select v-model="selparam2" size="small" placeholder="请选择列名"  style="width:120px; " >
                    <span v-for="item in ordCol" :key='item.key'>
                        <el-option :label="item.title" :value="item.key" v-if="item.thide=='N'"></el-option>
                    </span>
                </el-select>
                <el-input clearable :placeholder="input_hold" v-model="txtparam2" @keyup.enter.native="stfgChange" size="small" style="width:330px; height:30px; ">
                    <el-select v-model="oper2" slot="prepend"   style="width:110px; " >
                        <span v-for="item in operArr" :key='item.IDSEQ'>
                            <el-option :label="item.CNAME" :value="item.SUBNO"></el-option>
                        </span>
                    </el-select>
                    <el-button  slot="append" icon="el-icon-search" @click="stfgChange" ></el-button>
                </el-input>
            </div>    
        </div>  
        <el-table  :data="ordData" 
              border                            
              size="small"
              v-loading="loading"
              element-loading-text="加载中"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(255, 251, 255, 0.8)"
              :min-height="tabHeight" 
              :row-key="getRowKey"
              ref="reford"
              show-summary
              :summary-method="getSummary"
              highlight-current-row> 
            <el-table-column  v-for="(item ) in ordCol" :key="item.key"
                :prop="item.key"
                :label="item.title"
                show-overflow-tooltip
                :sortable="item.key!=='RN'&&item.edcss!=='checkbox'"
                :align="item.align"                                  
                :min-width="item.width">
                <template slot-scope="scope" >
                    <span v-if="item.thide==='N'&&item.edcss!=='checkbox'" >{{scope.row[item.key]}}</span>
                    <span v-if="item.edcss=='checkbox'">
                        <el-checkbox  true-label="Y" :disabled="item.key!=='SEL'"  false-label="N" v-model="scope.row[item.key]" style="width:100%"> &nbsp;</el-checkbox> 
                    </span>
                </template>
            </el-table-column> 
        </el-table>
        <!-- 分页查询 -->
        <!-- <div style="display:flex; line-height:35px;align-items:center;border:1px solid #ccc;padding-left:10px;" >              
            <Page :total="dataCount" :page-size="rowSize" show-total :current="current" @on-change="changePage"></Page>
            <span style="color:#606266;margin:7px 10px;font-size:12px;">每页</span>
            <el-select v-model="rowSize" size="small" style="width:70px;" @change="initPage">
                    <el-option 
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
            </el-select>
        </div> -->
 
        <!-- 提示框 -->
        <el-dialog :show-close="false" :visible.sync="promptWin" width="400px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" >
            <div slot="title" :style="{'background':'#409eff','color':'white','height':'35px'}">
                <span style="line-height:35px;height:35px;font-size:14px;padding-left:5px;">提示</span>
            </div>
            <div style=" margin:15px 10px">
                {{prompt}}
            </div>
            <div slot="footer">
                <Button type="primary"   @click="promptWin=false" style="margin-right:20px;">确定</Button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {getBaseData,getTabColOrData} from '../../api/user'; 
import {paramData} from '../../api/Select' 
export default {
    data () {
        return {
            payModal:false,
            promptWin:false,
            stfgSet:'ALL',
            prompt:'',
            input_hold:'请输入内容...',
            selparam:'IDNO',
            txtparam:'',
            matRow:{},
            selparam2:'',
            txtparam2:'',
            oper2:'like',
            goPage:'',
   
            ordData:[],
            loading:false,
            current:1,
            paymth:'ali',
            allList:[], //询价单集合
            v_username :this.$cookies.get('v_username' ), //用户名称 
            v_password:this.$cookies.get('v_password') ,//api 请求密码
            v_url:this.$store.state.queryUrl , //api请求路径   
            options: [ {
                value: 50,
                label: '50'
                }, {
                value: 100,
                label: '100'
            }],
            dataCount:0,
            rowSize:50,
            ordCol:[],
            operArr:[],
            oper:'like',
        }
    },
    components: {
      //alipay,  
    },
    computed: {
 
        tabHeight: function() {
            return (window.innerHeight - 190) + 'px';
        }, 
    },
    mounted () {
        //
        paramData('PARENTNO','41113','V_SYS_BASE').then((res)=>{
            this.operArr =res.data.result
        });   
    },
    created () {
        if (this.v_username){
            this.getTabCol() //表列集合
            this.getReqList('') //询价单主数据
        }else{
            let routeUrl = this.$router.resolve({
                path: '/',
                query: {lang:this.LANG },
            });
            window.open(routeUrl.href, '_self'); 
        }
    },
    methods: {
        selSure(){
            let data_=[]
            for(let k=0;k<this.ordData.length;k++){
                if(this.ordData[k].SEL==='Y'){
                    data_.push(this.ordData[k])
                }
            }
            if (data_.length>0){
                this.$emit('child-sure',data_)
            }else{
                this.prompt='请选择记录'
                this.promptWin =true
            }
        },
        //对指定列进行合计
        getSummary(param){
            const {columns ,data}=param
            const sums=[]
            columns.forEach((column,index)=>{
                if (index===0){
                    sums[index]='每页小计'
                    return
                }
                const values =data.map(item=>Number(item[column.property]));
                if(column.property=='QTY'||column.property=='POQTY'||column.property=='NOQTY'){
                    sums[index]=values.reduce((prev,curr)=>{ //为数组中每一个元素依次执行回调函数
                        const value=Number(curr)
                        if(!isNaN(value)){
                            return Number(Number(prev) +Number(curr) ).toFixed(2)
                        }else{
                            return prev
                        }
                    },0)
                }
            })
            return sums
        },
        //表行记录css
        tableRowClass({row, rowIndex}) {
            if (row.ORDSTFG  === 'Y') {
                return 'success-row';
            } else if (row.ORDSTFG ==='X') {
                return 'cancel-row';
            }
            return '';
        },
        getRowKey(row){
            return row.IDSEQ
        },
 
        //状态查询
        stfgChange(){
            let cond_=''
            if (this.selparam){
                if (this.oper==='like'){
                    cond_=cond_+' and upper('+this.selparam+') like upper(\'%'+this.txtparam+'%\')'
                }else{
                    cond_=cond_+' and upper('+this.selparam+')'+this.oper+'upper(\''+this.txtparam+'\')'
                }
            }
            if (this.selparam2){
                if (this.oper2==='like'){
                    cond_=cond_+' and upper('+this.selparam2+') like upper(\'%'+this.txtparam2+'%\')'
                }else{
                    cond_=cond_+' and upper('+this.selparam2+')'+this.oper2+'upper(\''+this.txtparam2+'\')'
                }
            }

            this.getReqList(cond_)
        }, 
        goNext(){
            if (this.goPage){
                this.changePage(this.goPage)
                this.current =Number(this.goPage)
            }else{
                this.$alert('请输入跳至页数', '提示', {
                    confirmButtonText: '确定',
                    showClose:false,
                    type:'info'
                })
            }
        },
        //表格页面导航
        changePage(index){
            var _start = ( index - 1 ) * this.rowSize;
            var _end = index * this.rowSize;
            this.ordData = this.allList.slice(_start,_end);
        },
        //初始化查询页条数
        initPage(){
            //产品明细
            this.dataCount =this.allList.length //总条数
            // 初始化显示，小于每页显示条数，全显，大于每页显示条数，取前每页条数显示
            if (this.allList.length < this.rowSize){
                this.ordData = this.allList          
            }else{
                this.ordData =this.allList.slice(0,this.rowSize) //返回选定元素的子数组，不包含结尾元素
            }
            
        },
 
        //询价单 
        getReqList(cond){
            let vm =this
            getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_orditm','','','','','','',' and stfg in (\'noship\',\'pay\') '+cond+' order by adddttm desc'  ).then((res) => { 
                this.ordData =res.data 
                //this.initPage() 
                if (res.data.length>0){
                    this.$refs['refreq'].setCurrentRow(res.data[0])
                } 
                this.$nextTick(() => { //有固定列时 查询后重新布局
                    if (this.$refs.refreq.doLayout){
                        this.$refs.refreq.doLayout();
                    }
                }) 
            })
        },
 
        //获取表列头字段  v-enter-number
        getTabCol(){
            var vm=this
            getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','115','fty','HEAD3','','',' order by sortby,lstseq' ).then((res) => {
                for (let k=0; k<res.data.length; k++){
                    vm.ordCol.push({ //向数组的开头 添加序列号
                        title: res.data[k].title,
                        key: res.data[k].key,
                        align: res.data[k].align,
                        width: res.data[k].width,  
                        edcss: res.data[k].EDCSS,  
                        thide: res.data[k].THIDE,  
                        fhide: res.data[k].FHIDE,                      
                        disabled: res.data[k].DISABLED,                       
                    })
                }
            })
 
        },
    }
}
</script>
<style scoped lang="less">
    .item-width  {
        width: 48%;
        color:#409EFF;
    }
  
</style>
<style lang="less">
    .el-table {
        th {
            padding: 0 ;
        }
        thead{
            height: 15px;
            color:rgb(11, 11, 59);
            font-size:12px;
            background:rebeccapurple;
        }
        td {
            padding: 5px ;
            font-size:11px;
        } 
    }
    .el-dialog__body{
        padding: 10px;
    }
    .el-dialog__header{
        padding: 0px;
    }
    .el-dialog__footer{
        padding: 5px;
        border-top:1px solid #ccc;
    } 
    .el-dialog__headerbtn{font-size: 20px; background:white;top:5px !important;}
    //.el-dialog__headerbtn i { background:white;font-size: 20px;  }
    .el-table .success-row {
        color:#959997;
    }
    .el-table .cancel-row {
        color: #e00e66;
    }
</style>