<template>
    <div style="height:100%;background:#fff">
        <div style=" background:white;line-height:30px;height:40px;border-bottom:2px solid #389"> 
            <div style="padding-top:3px;display:flex;width:1400px; padding-left:20px; ">               
                <div style="width:70px">
                    <i class="iconfont icon-xinzeng" style="color:#409EFF;font-size:14px;margin-right:5px;margin-left:8px;"></i>
                    <el-link :disabled="addBool" @click="addRec" :underline="false" style="margin-top:0px;"><span style="font-size:13px;margin-right:15px;">新增</span></el-link>
                </div>
                <div style="margin-right:10px;font-size:12px;margin-left:5px;">状态</div>
                <el-select v-model="stfgSet"   size="small" @change="stfgChange" style="height:30px;width:110px">
                    <el-option label="所有" value="ALL"></el-option>
                    <el-option label="未审核" value="N"></el-option>
                    <el-option label="已审核" value="Y"></el-option>
                </el-select>
                <span style=" margin-left:20px;font-size:12px;margin-right:10px;">字段查询</span>
                <el-select v-model="selparam" size="small" placeholder="请选择列名" clearable  style="width:150px; " >
                    <span v-for="item in poCol" :key='item.key'>
                        <el-option :label="item.title" :value="item.key" v-if="item.thide=='N'"></el-option>
                    </span>
                </el-select>
                <el-input clearable :placeholder="input_hold" v-model="txtparam" @keyup.enter.native="stfgChange" size="small" style="width:320px; height:30px; ">
                    <el-select v-model="oper" slot="prepend"   style="width:100px; " >
                        <span v-for="item in operArr" :key='item.IDSEQ'>
                            <el-option :label="item.CNAME" :value="item.SUBNO"></el-option>
                        </span>
                    </el-select>
                    <el-button  slot="append" icon="el-icon-search" @click="stfgChange" ></el-button>
                </el-input> 
                <el-select v-model="selparam2" size="small" placeholder="请选择列名" clearable  style="width:150px; " >
                    <span v-for="item in poCol" :key='item.key'>
                        <el-option :label="item.title" :value="item.key" v-if="item.thide=='N'"></el-option>
                    </span>
                </el-select>
                <el-input :placeholder="input_hold" v-model="txtparam2" @keyup.enter.native="stfgChange" size="small" style="width:320px; height:30px; ">
                    <el-select v-model="oper2" slot="prepend"   style="width:100px; " >
                        <span v-for="item in operArr" :key='item.IDSEQ'>
                            <el-option :label="item.CNAME" :value="item.SUBNO"></el-option>
                        </span>
                    </el-select>
                    <el-button  slot="append" icon="el-icon-search" @click="stfgChange" ></el-button>
                </el-input> 
            </div>
        </div>  
        <div>
        <el-table  :data="poData" 
              border                            
              size="small"
              v-loading="loading"
              element-loading-text="加载中"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(255, 251, 255, 0.8)"
              :height="subHeight" 
              :row-key="getRowKey"
              ref="refpo"
              highlight-current-row
              style="overflow:auto;margin-top:1px;">          
            <el-table-column  v-for="(item,index ) in poCol.filter(item=>item.thide=='N')" :key="index" 
                :prop="item.key"
                :label="item.title"
                show-overflow-tooltip
                :align="item.align"                                  
                :sortable ="item.key!=='RN'&&item.edcss!=='checkbox'"
                :min-width="item.width">
                <template  slot-scope="scope" >
                    <el-link v-if="item.key=='IDNO'" :underline="false" @click="repShow(scope.row)"><div style="color:#409eff;border-bottom:1px solid red;width:100%;text-align:left;font-size:10px ">{{scope.row[item.key]}}</div> </el-link>
                    <el-checkbox v-else-if="item.edcss=='checkbox'" disabled true-label="Y" false-label="N" v-model="scope.row[item.key]"></el-checkbox>
                    <span v-else v-text="scope.row[item.key]"></span>
                </template>
            </el-table-column>         
             <el-table-column
                fixed="right"
                label="操作"
                align="center"
                width="170">
                <template slot-scope="scope">
                    <span v-if="scope.row.STFG=='Y'"> 
                        <i class="iconfont icon-chakan" style="color:#409EFF;font-size:12px;margin-right:5px;"></i>
                        <el-link   @click="editRec(scope.row,'Y',scope.$index )" :underline="false"  ><span style="font-size:11px;margin-right:5px;">查看 </span></el-link>
                    </span>
                    <span v-if="scope.row.STFG=='N'||scope.row.STFG=='U'"> 
                        <i class="iconfont icon-bianji" style="color:#409EFF;font-size:12px;margin-right:5px;"></i>
                        <el-link :disabled="editBool" @click="editRec(scope.row,'N',scope.$index )" :underline="false"  ><span style="font-size:11px;margin-right:5px;">编辑 </span></el-link>
                    </span>
                    <span v-if="scope.row.STFG=='N'||scope.row.STFG=='U'"> 
                        <i class="iconfont icon-faqi" style="color:#409EFF;font-size:12px;margin-right:5px;"></i>
                        <el-link :disabled="editBool" @click="stfgRec(scope.row,'A',scope.$index )" :underline="false"  ><span style="font-size:11px;margin-right:5px;">发起 </span></el-link>
                    </span>
                    <span v-if="scope.row.STFG=='A'"> 
                        <i class="iconfont icon-shenhe" style="color:#409EFF;font-size:12px;margin-right:5px;"></i>
                        <el-link :disabled="cfmBool" @click="stfgRec(scope.row,'Y',scope.$index )" :underline="false"  ><span style="font-size:11px;margin-right:5px;">审核 </span></el-link>
                    </span>
                    <span v-if="scope.row.STFG=='Y' "> 
                        <i class="iconfont icon-fanshenhe" style="color:#409EFF;font-size:12px;margin-right:5px;"></i>
                        <el-link :disabled="cfmBool" @click="stfgRec(scope.row,'N',scope.$index )" :underline="false"  ><span style="font-size:11px;margin-right:5px;">弃审 </span></el-link>
                    </span>
                    <span v-if=" scope.row.STFG=='A'"> 
                        <i class="iconfont icon-chexiao" style="color:#409EFF;font-size:12px;margin-right:5px;"></i>
                        <el-link :disabled="cfmBool" @click="stfgRec(scope.row,'U',scope.$index )" :underline="false"  ><span style="font-size:11px;margin-right:5px;">驳回 </span></el-link>
                    </span>
                    <span v-if="scope.row.STFG=='N'||scope.row.STFG=='U'"> 
                        <i class="iconfont icon-shanchu" style="color:#409EFF;font-size:12px;margin-right:5px;"></i>
                        <el-link :disabled="editBool" @click="stfgRec(scope.row,'X',scope.$index )" :underline="false"  ><span style="font-size:11px;margin-right:5px;">删除 </span></el-link>
                    </span>
                </template>
            </el-table-column>  
        </el-table>
        </div>
        <!-- 分页查询 -->
        <div style="display:flex; align-items:center;line-height:30px;height:35px; padding-left:10px;background:white;font-size:12px" >              
            <Page :total="dataCount" :page-size="rowSize" show-total :current="current" @on-change="changePage"></Page>
            <span style="color:#606266;margin:7px 10px;font-size:12px;">每页</span>
            <el-select v-model="rowSize" size="small" style="width:70px;" @change="initPage">
                    <el-option 
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
            </el-select>
            <span style="color:#606266;margin:7px 10px;font-size:12px;">跳至</span>
            <el-input v-enter-number size="small" v-model="goPage" style="width:50px;"></el-input> 
            <span style="color:#606266;margin:7px 5px;font-size:12px;">页</span>
            <el-button size="small" @click="goNext" type="primary" style="width:50px;margin-left:15px;height:30px;">GO</el-button>
 
        </div>
        <!-- 提示框 -->
        <el-dialog :show-close="false" :visible.sync="promptWin" width="400px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" >
            <div slot="title" :style="{'background':'#409eff','color':'white','height':'35px'}">
                <span style="line-height:35px;height:35px;font-size:14px;padding-left:5px;">提示</span>
            </div>
            <div style=" margin:15px 10px">
                {{prompt}}
            </div>
            <div slot="footer">
                <Button type="primary"   @click="promptWin=false" style="margin-right:20px;">确定</Button>
            </div>
        </el-dialog>
        <!-- 新增、编辑表单 -->
        <el-dialog class="pub_dialog" :visible.sync="addWin" :width="tabWidth" :close-on-press-escape="false" :close-on-click-modal="false" append-to-body top="4vh">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">{{addFlag==='create'?'新增记录':'编辑记录'}}</span>
            </div> 
            <div style="height:88vh;overflow-y:auto;padding-bottom:100px;">
                <Form :model="poRow" :label-width="110" ref="dtlform"  label-position="right" inline  :rules="ruleValidate">  
                    <span v-for="(item,index) in poCol" :key="index" >
                        <FormItem   :label="item.title"  :class="item.key=='PKGRMK'||item.key=='MAINRMK'?'item-one':'item-width'" v-if="item.fhide==='N'" :prop="item.key"  >                  
                            <template slot="label" v-if="item.key=='PKGRMK'"><el-link @click="selPkg" :underline="false"><span style="border-bottom:1px solid #409eff;color:#409eff">{{item.title}}</span></el-link></template>
                            <template slot="label" v-else-if="item.key=='MAINRMK'"><el-link @click="selMain" :underline="false"><span style="border-bottom:1px solid #409eff;color:#409eff">{{item.title}}</span></el-link></template>
                            <template slot="label" v-else>{{item.title}}</template>
                            <el-date-picker :disabled="saveFlag" type="date"  v-if="item.edcss==='date'" v-model="poRow[item.key]" clearable value-format="yyyy-MM-dd" style="width:100%" ></el-date-picker>   
                            <el-input-number :disabled="saveFlag" :precision="item.dotnum"  v-else-if="item.edcss==='number'" v-model="poRow[item.key]" style="width:100%" ></el-input-number>   
                            <el-select filterable  v-else-if="item.key==='SUPNO'" style="width:100%" v-model="poRow[item.key]">
                                <el-option v-for="(item,index) in supArr" :key="index" :label="item.SUPNM" :value="item.IDNO"></el-option>
                            </el-select>
                            <el-select   v-else-if="item.key==='STAFF'" style="width:100%" v-model="poRow[item.key]">
                                <el-option v-for="(item,index) in empArr" :key="index" :label="item.EMPNM" :value="item.EMPNM"></el-option>
                            </el-select>
                            <el-input :disabled="saveFlag ||item.disabled==='Y'?true:false" type="textarea" v-else-if="item.key=='PKGRMK'" :rows="4" v-model="poRow[item.key]" ></el-input>
                            <el-input :disabled="saveFlag ||item.disabled==='Y'?true:false" type="textarea" v-else-if="item.key=='MAINRMK'" :rows="6" v-model="poRow[item.key]" ></el-input>
                            <el-input :disabled="saveFlag ||item.disabled==='Y'?true:false" type="text" v-else   v-model="poRow[item.key]" ></el-input>
                        </FormItem>
                    </span>
                </Form>

                <el-tabs v-model="activeTab">
                    <el-tab-pane name="tab01">
                        <span slot="label">采购明细【<i class="iconfont icon-xiazai" style="font-size:14px;margin-right:5px;margin-left:8px;"></i>
                        <el-link :disabled="addBool||saveFlag" @click="refOrd" :underline="false" ><span style="font-size:13px;margin-right:15px;">引用销售订单</span></el-link> 】</span>
                    </el-tab-pane>
                    <el-tab-pane name="tab02"><span slot="label">审批日志</span></el-tab-pane>
                </el-tabs>
                <span v-if="activeTab=='tab01'">
                <el-table  :data="dtlData" 
                    border                            
                    size="small"
                    :height="tabHeight" 
                    show-summary
                    :summary-method="getListSum"
                    :row-key="getDtlKey"
                    :row-click="dtlClick"
                    ref="refdtl"
                    highlight-current-row > 
                    <el-table-column label="序号" type="index" :width="70" ></el-table-column>
                    <el-table-column  v-for="(item,index ) in dtlArr" :key="index"
                        :prop="item.key"
                        :fixed="item.key==='RN'"
                        :label="item.title"
                        show-overflow-tooltip
                        :align="item.align"                                  
                        sortable
                        :min-width="item.width">
                        <template slot-scope="scope" >
                            <el-checkbox  v-if="item.edcss==='checkbox'" true-label="Y"  false-label="N" v-model="scope.row[item.key]" style="width:100%"></el-checkbox> 
 
                            <el-select  v-else-if="item.key==='TAX'" size="small" @change="rowChg(scope.row)" v-model="scope.row[item.key]"  style="width:100%" filterable>
                                <el-option v-for="(item,index) in taxArr" :key="index" :value="item.SUBNO" :label="item.CNAME" ></el-option>
                            </el-select> 
                            <span v-else-if="item.edcss==='number'">
                                <el-link :underline="false"  style="width:99%;color:red;cursor:pointer;" v-show="!showVisible||(editIndex!==scope.$index)||(v_column!==scope.column.property)"  @click="editCurrRow(scope.$index,scope.column.property)">{{scope.row[item.key]}}</el-link> 
                                <!-- <el-input-number :precision="item.dotnum" :id="scope.$index+scope.column.property" :controls="false"  size="mini" style="width:100%" v-show="showVisible &&(editIndex==scope.$index)&&(v_column==scope.column.property)" @change="rowChg(scope.row)" @blur="showVisible=false" v-model="scope.row[item.key]"></el-input-number> -->
                                <el-input v-enter-number :id="scope.$index+scope.column.property"   size="mini" style="width:100%" v-show="showVisible &&(editIndex==scope.$index)&&(v_column==scope.column.property)" @change="rowChg(scope.row)" @blur="showVisible=false" v-model="scope.row[item.key]"></el-input>
                            </span>
                            <el-link v-else-if="item.key=='IMGPATH'" :underline="false" @click="seeLink(scope.row[item.key])"><div style="color:#409eff;border-bottom:1px solid red ">{{scope.row[item.key]}}</div> </el-link>
                            <span  v-else v-text="scope.row[item.key]" > </span>
                        </template>
                    </el-table-column> 
 
                    <el-table-column
                        fixed="right"
                        label="操作"
                        align="center"
                        width="130">
                        <template slot-scope="scope">
                            <i class="iconfont icon-bianji" style="color:#409EFF;font-size:14px;margin-right:5px; "></i>
                            <el-link :disabled="saveFlag"  @click="dtlEdit(scope.row,scope.$index )" :underline="false" ><span style="font-size:10px;margin-right:5px;">编辑</span></el-link>
                            <i class="iconfont icon-shanchu1" style="color:#409EFF;font-size:14px;margin-right:5px; "></i>
                            <el-link :disabled="saveFlag"  @click="dtlDel(scope.row,scope.$index )" :underline="false" ><span style="font-size:10px;margin-right:5px;">删除</span></el-link>
 
                        </template>
                    </el-table-column>  
                </el-table>
                </span>
                <span v-if="activeTab=='tab02'">
                    <el-table  :data="cfmData" 
                        border                            
                        size="small"
                        :height="tabHeight" 
                        :row-key="getDtlKey"
                        highlight-current-row > 
                    <el-table-column  v-for="(item,index ) in cfmCol.filter(item=>item.thide=='N')" :key="index"
                        :prop="item.key"
                        :label="item.title"
                        show-overflow-tooltip
                        :align="item.align"                                  
                        :sortable="item.key!=='RN'"
                        :min-width="item.width">
                    </el-table-column>
                    </el-table>
                </span>
            </div>
            <div style="height:40px;line-height:30px;border-top:2px solid #ccc;padding-top:4px  " >
                <span style="float:right">
                    <i v-if="poRow['STFG']==='N'" style="margin-left:20px; color:red;font-size:28px; " class="iconfont icon-weishenhe3"> </i>
                    <i v-else-if="poRow['STFG']==='Y'" style="margin-left:20px; color:red;font-size:28px; " class="iconfont icon-yishenhe1"> </i>
                    <i v-else-if="poRow['STFG']==='X'" style="margin-left:20px; color:red;font-size:28px; " class="iconfont icon-yizuofei"> </i>
                    <el-button type="info" @click="addWin=false" size="small" icon="iconfont icon-shanchu" style="margin-left:30px;height:35px">取消</el-button>
                    <el-button type="primary" size="small" :disabled="addBool||saveFlag" @click="saveRec" icon="iconfont icon-baocun" style="margin-left:30px;height:35px">保存</el-button>
                </span>
            </div>
        </el-dialog>
 
        <!-- 选择商品 -->
        <el-dialog class="pub_dialog" :visible.sync="selPrdWin" :width="tabWidth" append-to-body  :close-on-click-modal="false" :close-on-press-escape="false" top="1vh">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">选择商品</span>
            </div> 
            <prdInfo  @child-prd="prdSel"></prdInfo>
        </el-dialog>
        <!-- 选择销售订单 -->
        <el-dialog class="pub_dialog" :visible.sync="ordWin" :width="tabWidth" append-to-body  :close-on-click-modal="false" :close-on-press-escape="false" top="4vh">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">选择销售订单</span>
            </div> 
            <div style="height:88vh;overflow-y:auto;padding-bottom:100px;"><ordInfo ref="refsale" @child-sure="ordSel"></ordInfo></div>
        </el-dialog>
        <!--  明细编辑 -->
        <el-dialog   :visible.sync="dtlWin" width="1200px" :close-on-press-escape="false" :close-on-click-modal="false" append-to-body top="1vh">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">{{dtlFlag==='create'?'新增记录':'编辑记录'}}</span>
            </div> 
            <Form :model="dtlRow" :label-width="120" ref="secForm" label-position="right" inline :rules="dtlValidate">  
                <span v-for="(item,index) in dtlCol" :key="index" >
                    <FormItem  :label="item.title"  :class="item.key=='RMK'?'item-one': 'item-width'" :prop="item.key"  v-if="item.fhide==='N'">                  
                        
                        <el-checkbox border v-if="item.edcss==='checkbox'" true-label="Y"  false-label="N" v-model="dtlRow[item.key]" style="width:100%"></el-checkbox> 
                        <el-input type="text" v-else-if="item.key==='MATNO'" disabled v-model="dtlRow[item.key]">
                            <div slot="append">
                                <el-button @click="prdChoose" icon="iconfont icon-icon-choose" style="width:50px;color:blue;font-weight:600;font-size:22px;"></el-button>
                            </div>
                        </el-input>
                        <el-select  v-else-if="item.key==='TAX'" @change="prcVal" v-model="dtlRow[item.key]"  style="width:100%" filterable>
                            <el-option v-for="(item,index) in taxArr" :key="index" :value="item.SUBNO" :label="item.CNAME" ></el-option>
                        </el-select> 
                        <el-input-number  :precision="item.dotnum"  v-else-if="item.edcss==='number'" @change="prcVal"  v-model="dtlRow[item.key]" style="width:100%" ></el-input-number>   
                        <el-input  type="textarea" :rows="3" v-else-if="item.key==='RMK'"   v-model="dtlRow[item.key]"></el-input>
                        <!-- <el-input  type="text" v-else-if="item.key==='QTY'||item.key==='TAXPRC'"  @change="prcVal"  v-model="dtlRow[item.key]"></el-input> -->
                        <el-input  type="text" v-else :disabled="item.disabled==='Y'?true:false"  v-model="dtlRow[item.key]"></el-input>
                    </FormItem>
                </span>
            </Form>
            <div style="height:40px;line-height:30px;border-top:2px solid #ccc;padding-top:4px; ">
                <span style="float:right">
                    <el-button type="info" size="small" icon="iconfont icon-shanchu"  @click="dtlWin=false" style="margin-right:50px;">取消</el-button>
                    <el-button type="primary" size="small" icon="iconfont icon-baocun" @click="dtlAppend"   >确定</el-button>
                </span>
            </div>
        </el-dialog>
        <!-- 备注选择 -->
        <el-dialog v-dialogDrag :visible.sync="rmkWin" width="900px" append-to-body  :close-on-click-modal="false" :close-on-press-escape="false" top="1vh">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">包装条款</span>
            </div> 
            <el-input  type="textarea" :rows="7"  v-model="pkgTmpl"></el-input>
            <span slot="footer"  >
                <el-button type="info" @click="rmkWin=false" size="small"  icon="iconfont icon-shanchu">  取消</el-button>
                <el-button @click="rmkSure" size="small" type="primary" style="margin-left:30px;" icon="iconfont icon-baocun"  > 确定 </el-button> 
            </span>
        </el-dialog>
        <el-dialog v-dialogDrag :visible.sync="mainWin" width="1200px" append-to-body  :close-on-click-modal="false" :close-on-press-escape="false" top="1vh">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">重要条款</span>
            </div> 
            <el-input  type="textarea" :rows="13"  v-model="mainTmpl"></el-input>
            <span slot="footer"  >
                <el-button type="info" @click="mainWin=false" size="small"  icon="iconfont icon-shanchu">  取消</el-button>
                <el-button @click="mainSure" size="small" type="primary" style="margin-left:30px;" icon="iconfont icon-baocun"  > 确定 </el-button> 
            </span>
        </el-dialog>
        <el-dialog v-dialogDrag :visible.sync="showWin" width="800px" append-to-body>
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">预览</span>
            </div>
            <el-image fit="scale-down"  style=" margin:5px auto;height:500px;width:100% " :src="listurl"></el-image>
        </el-dialog>
        <!-- report preview -->
        <el-dialog class="pub_dialog" v-dialogDrag  :visible.sync="repWin" :width="tabWidth" :close-on-press-escape="false" :close-on-click-modal="false" append-to-body top="4vh">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">报表预览</span>
            </div> 
            <div style="height:88vh;overflow-y:auto;padding-bottom:100px;">
                <iframe :src="repUrl" frameborder="0" style="width: 100%; height: 100%"></iframe>
            </div>
        </el-dialog>
        <!-- 审批意见 -->
        <el-dialog v-dialogDrag :visible.sync="cfmWin" width="900px" append-to-body  :close-on-click-modal="false" :close-on-press-escape="false" top="1vh">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">意见内容</span>
            </div> 
            <div style="font-size:11px">操作员：</div>
            <el-input  type="text" readonly  v-model="v_oper"  ></el-input>
            <div style="font-size:11px">操作时间：</div>
            <el-input  type="text" readonly  v-model="v_dttm" ></el-input>
            <div style="font-size:11px">意见：</div>
            <el-input  type="textarea" :rows="7"  v-model="v_rmk"></el-input>
            <span slot="footer"  >
                <el-button type="info" @click="cfmWin=false" size="small"  icon="iconfont icon-shanchu">  取消</el-button>
                <el-button @click="cfmSure" size="small" type="primary" style="margin-left:30px;" icon="iconfont icon-baocun"  > 确定 </el-button> 
            </span>
        </el-dialog>
    </div>
</template>
<script>
import {getBaseData,getTabColOrData} from '../../api/user'; 
import {paramData,getDate} from '../../api/Select' 
// import supInfo from '../datamgt/sup_tmpl.vue'
import prdInfo from '../datamgt/product_tmpl.vue'
import ordInfo from '../datamgt/ord_tmpl.vue'

export default {
    name:'po_order',
    data () {
        return {
            activeTab:'tab01',
            cfmWin:false,
            v_rmk:'',
            v_stfg:'',
            v_oper:'',
            v_dttm:'',
            v_pkseq:'',
            v_index:'',
            v_row:'',
            cfmData:[],
            editIndex:-1, //当前编辑行index
            showVisible:false,
            showWin:false,
            listurl:'',
            mainTmpl:'',
            pkgTmpl:'',
            mainWin:false,
            rmkWin:false,
            dtlRow:{},
            ordWin:false,
            dtlWin:false,
            supWin:false,
            selPrdWin:false,
            taxArr:[],
            promptWin:false,
            dtlFlag:'create',
            prdRow:{},
            rowFlag:true,
            addFlag:'create',
            supRow:{
                SUPNO:'' ,//供应商编码
                SUPNM:'', //供应商名称
                RMK:'', //备注
            },
            rowDtl:{},
            cfmCol:[],
            delPrdArr:[],
            stfgSet:'ALL',
            delDtlArr:[],
            dtlArr:[],
            prompt:'',
            input_hold:'请输入内容...',
            ruleValidate:{},
            dtlValidate:{},
            selparam:'IDNO',
            txtparam:'',
            selparam2:'',
            txtparam2:'',
            oper2:'like',
            poRow:{},
            prdFlag:'create',
            goPage:'',
            rowIdseq:'',
            poData:[],
            cfmBool:false, //审核 权限
            addBool:false, //新增 权限
            editBool:false, //编辑权限
            cancelBool:false, //作废权限
            loading:false,
            current:1,
            saveFlag:false,
            allList:[], //询价单集合
            dtlData:[],
            matData:[],
            addWin:false,
            v_username :this.$cookies.get('v_username' ), //用户名称 
            v_password:this.$cookies.get('v_password') ,//api 请求密码
            v_url:this.$store.state.queryUrl , //api请求路径   
            v_usernm :this.$cookies.get('v_usernm' ), //用户名称 
            options: [{
                value: 50,
                label: '50'
                }, {
                value: 100,
                label: '100'
            }],
            dataCount:0,
            rowSize:50,
            dtlCol:[],
            matCol:[],
            poCol:[],
            operArr:[],
            prcCol:[],
            prcData:[],
            prdIndex:'',
            oper:'like',
            colory:'#959997', //审核颜色
            colorx:'#e00e66', //作废颜色
            v_idseq:this.$cookies.get('idseq'),
            dtlIndex:'',
            supArr:[],
            empArr:[],
            repUrl:'',
            repWin:false,
            v_column:'',
        }
    },
    components: {
     // supInfo,  //注册组件
      prdInfo,  //产品列表
      ordInfo, //销售订单
    },
    computed: {
        subHeight: function() {
            return (window.innerHeight - 155) + 'px';
        }, 
        tabWidth: function() {
            return (window.innerWidth - 140) + 'px';
        },
        tabHeight: function() {
            return (window.innerHeight - 625) + 'px';
        },  
    },
    mounted () {
        //操作符
        paramData('PARENTNO','41113','V_SYS_BASE').then((res)=>{
            this.operArr =res.data.result
        });  
        getBaseData(this.$store.state.nologinUrl, '','','','','v_bs_sup',' and substr(supty,1,3)=\'236\'').then(res=>{
            this.supArr =res.data.result
        })
        paramData('','','V_EMPIN').then((res)=>{
            this.empArr =res.data.result
        });
        getBaseData(this.$store.state.nologinUrl,'PARENTNO','57345','','','v_sys_base',' order by sortby,idseq').then(res=>{
            res.data.result.forEach((item,index)=>{
                if (index ==res.data.result.length-1){
                    this.pkgTmpl =this.pkgTmpl +item['CNAME']
                }else{
                    this.pkgTmpl =this.pkgTmpl +item['CNAME']+'\r\n'
                }
            })
        })
        getBaseData(this.$store.state.nologinUrl,'PARENTNO','57352','','','v_sys_base',' order by sortby,idseq').then(res=>{
            res.data.result.forEach((item,index)=>{
                if (index ==res.data.result.length-1){
                    this.mainTmpl =this.mainTmpl +item['CNAME']
                }else{
                    this.mainTmpl =this.mainTmpl +item['CNAME']+'\r\n'
                }
            })
        })
        //税率
        paramData('PARENTNO','19691','V_SYS_BASE').then((res)=>{
            this.taxArr =res.data.result
        });
         //用户权限表
        getBaseData(this.$store.state.nologinUrl, 'idseq',this.v_idseq,'frmidseq','35','v_sys_user_priv','').then(res=>{
            this.privArr=res.data.result
            if (this.privArr[0].ISCFM==='N'){ //审核
                this.cfmBool=true
            }if (this.privArr[0].ISADD==='N'||this.privArr[0].DEPTID==='19541'){ //新增 ,部门为供应商时不能新增
                this.addBool=true
            }if (this.privArr[0].ISUPD==='N'){ //编辑
                this.editBool=true
            }if (this.privArr[0].ISCANCEL==='N'){ //作废
                this.cancelBool=true
            }
        })   
         //动态生成表单验证规则
        getTabColOrData(this.v_url,this.v_username,this.v_password,'v_sys_form_fld','idseq','35','FLDTY','HEAD','NOTNULL','Y').then((res) => {
            let ruleObj={}
            let ruleVal=[]
            this.notNull = res.data.result
            res.data.forEach(item => {
                let v_fldnm =item.FLDNM
                let obj1={required:true,message:v_fldnm+'不能为空',trigger: 'blur'}
                ruleVal =[]
                ruleVal.push(obj1)
                ruleObj[item.FLDNO]=ruleVal                  
            });
            this.ruleValidate =ruleObj
        });
        getTabColOrData(this.v_url,this.v_username,this.v_password,'v_sys_form_fld','idseq','35','FLDTY','HEAD2','NOTNULL','Y').then((res) => {
            let ruleObj={}
            let ruleVal=[]
            res.data.forEach(item => {
                let v_fldnm =item.FLDNM
                let obj1={required:true,message:v_fldnm+'不能为空' }
                ruleVal =[]
                ruleVal.push(obj1)
                ruleObj[item.FLDNO]=ruleVal                  
            });
            this.dtlValidate =ruleObj
        });
    },
    created () {
        if (this.v_username){
            this.getTabCol() //表列集合
            this.stfgChange() //询价单主数据    
        }else{
            let routeUrl = this.$router.resolve({
                path: '/',
                query: {lang:this.LANG },
            });
            window.open(routeUrl.href, '_self'); 
        }
    },
    methods: {
 
        editCurrRow(index,str){
            let that =this
            this.editIndex=index //不加此，整个列都会一块变成可编辑
            this.showVisible=true
            this.v_column =str
            let id=index +str
            //也可以用 this.$nextTick, 个人感觉加个0.1秒的延时比下次渲染灵活一点
            setTimeout(()=>{
                document.getElementById(id).focus()
            },100)
        },
 
        dtlClick(){
            this.rowFlag=false
        },
        getListSum(param){
            const {columns ,data}=param
            const sums=[]
            columns.forEach((column,index)=>{
                if (index===0){
                    sums[index]='合计'
                    return
                }
                const values =data.map(item=>Number(item[column.property]));
                if(column.property==='TAXAMT'||column.property==='AMT'|| column.property==='QTY'){
                    sums[index]=values.reduce((prev,curr)=>{ //为数组中每一个元素依次执行回调函数
                        const value=Number(curr)
                        if(!isNaN(value)){
                            return Number(Number(prev) +Number(curr) ).toFixed(1)
                        }else{
                            return prev
                        }
                    },'')
                }
            })
            return sums
        },
        seeLink(path){
            this.showWin =true
            this.listurl =path
        },
        //包装条款确认选择
        rmkSure(){
            if (this.poRow['PKGRMK']){
                this.$confirm('包装条款不为空时，会覆盖已有记录，要继续吗','提示',{
                    confirmButtonText: '确定',
                    cancelButtonText:'取消',
                    customClass:'messageBox',
                    showClose:false,
                    closeOnClickModal:false,
                    closeOnPressEscape:false,
                    type:'info',
                }).then(()=>{
                    this.poRow['PKGRMK'] =this.pkgTmpl
                    this.rmkWin=false
                }).catch(()=>{})
            }else{
                this.poRow['PKGRMK'] =this.pkgTmpl
                this.rmkWin=false
            }
        },
        //重要条款确认
        mainSure(){
            if (this.poRow['MAINRMK']){
                this.$confirm('重要条款不为空时，会覆盖已有记录，要继续吗','提示',{
                    confirmButtonText: '确定',
                    cancelButtonText:'取消',
                    customClass:'messageBox',
                    showClose:false,
                    closeOnClickModal:false,
                    closeOnPressEscape:false,
                    type:'info',
                }).then(()=>{
                    this.poRow['MAINRMK'] =this.mainTmpl
                    this.mainWin=false
                }).catch(()=>{})
            }else{
                this.poRow['MAINRMK'] =this.mainTmpl
                this.mainWin=false
            }
        },
        selMain(){
            this.mainWin=true
        },
        selPkg(){
            this.rmkWin=true
        },
        repShow(row){
            this.repWin =true
            //this.repUrl ='http://123plus.xyz:8092/FR/ReportServer?reportlet=/Reports/porep.cpt'+'&p_pono='+row['IDNO']
            //window.open('http://123plus.xyz:8092/webroot/decision/view/report?viewlet=/Reports/porep.cpt'+'&p_pono='+row['IDNO'],'_blank')
            this.repUrl ='http://123plus.xyz:8092/webroot/decision/view/report?viewlet=/Reports/porep.cpt'+'&p_pono='+row['IDNO'] 
        },
        //销售订单选择
        ordSel(data_){
            if (data_.length>0){ //IDNO,MATNM,MATDESC,MODELNM,MATTYNM,PRC,TTLAMT,QTY ::TAXPRC,AMT
                for(let k=0;k<data_.length;k++){
                    let obj_={}
                    Object.assign(obj_,{MATNO:data_[k].IDNO,MATNM:data_[k].MATNM,MATDESC:data_[k].MATDESC,IDSEQ:this.rowIdseq,
                        MODELNM:data_[k].MODELNM,MATTYNM:data_[k].MATTYNM, QTY:data_[k].QTY, RMK:data_[k].RMK,ORDQTY:data_[k].QTY,
                        IMGPATH:data_[k].DEFPIC,REFIDNO:data_[k].ORDNO,REFIDSEQ:data_[k].IDSEQ,REFLSTSEQ:data_[k].LSTSEQ})
                    this.dtlData.push(obj_)
                }   
            }
            this.ordWin=false
        },
        refOrd(){
            this.ordWin =true
            this.$refs['refsale'].getReqList('')
        },
        rowChg( row){
            let tax_=!Number(row['TAX'])?'0':Number(row['TAX'])
            let qty_=Number(row['QTY'])
            let taxprc_=Number(row['TAXPRC']).toFixed(2)
            Object.assign(row,{'AMT':Number(taxprc_ *qty_).toFixed(2)})
            Object.assign(row,{'TAXAMT':Number(taxprc_*tax_/100*qty_).toFixed(2)} )
        },
        prcVal(){
            let tax_=Number(this.dtlRow['TAX'])
            let qty_=Number(this.dtlRow['QTY'])
            let taxprc_=Number(this.dtlRow['TAXPRC']).toFixed(2)
            this.dtlRow['AMT'] =Number(taxprc_ *qty_).toFixed(2)
            this.dtlRow['TAXAMT'] =Number(taxprc_*tax_/100*qty_).toFixed(2)
        },
        //供应商选择
        orgChoose(){
            this.supWin=true
        },
        dtlEdit(row,index){
            this.dtlWin=true
            this.dtlRow=JSON.parse(JSON.stringify(row))
            this.dtlIndex=index
            this.rowDtl=row
            this.dtlFlag='edit'
        },
        //采购明细 
        getDtlList(idseq ){
            this.dtlData=[]
            getTabColOrData(this.v_url,this.v_username,this.v_password,'v_po_orddtl','idseq',idseq,'','','','',' order by sortby,lstseq' ).then((res) => { 
                this.dtlData =res.data 
                this.$nextTick(() => { //有固定列时 查询后重新布局
                    this.$refs.refdtl.doLayout();
                })
            })
            this.cfmData=[]
            getTabColOrData(this.v_url,this.v_username,this.v_password,'v_po_rmk','idseq',idseq,'','','','',' order by dttm desc' ).then((res) => { 
                this.cfmData =res.data 
            }) 
        },
        //选择产品
        prdChoose(){
            this.selPrdWin=true
        },
 
        prdSel(row){
            this.dtlRow['MATNO']=row['IDNO']
            this.dtlRow['MATNM']=row['MATNM']
            this.dtlRow['MATTYNM']=row['MATTYNM']
            this.dtlRow['MODELNM']=row['MODELNM']
            this.dtlRow['MATDESC']=row['MATDESC']
            this.dtlRow['IMGPATH']=row['IMGPATH']
            this.selPrdWin=false
        },
 
        //明细编辑、删除
        dtlDel(row,index){
            this.delDtlArr.push(row)
            this.dtlData.splice(index,1 )
        },
 
        //明细选择确认
        dtlAppend(){
            this.$refs['secForm'].validate((valid) => {
                if (valid) { 
                    if (this.dtlFlag==='create'){
                        this.dtlData.push(this.dtlRow)
                    }else{
                        this.dtlData.splice(this.dtlIndex,1,Object.assign(this.rowDtl,this.dtlRow) )
                    }
                    this.dtlWin=false
                }
            })
        },
        addDtl(){
            this.dtlFlag='create'
            this.dtlWin=true
            this.dtlRow={TAX:'13'}
            this.delDtlArr=[]
        },
        //子件供应商选择
        sup_Selrec(){
            this.supWin=true
        },
        supSel(idseq,val){
            this.poRow['SUPNO']=idseq
            this.poRow['SUPNM']=val
            this.supWin=false
        },
        //编辑记录
        editRec(row,val,index){
            if (val==='Y'){  //仅查看
                this.saveFlag=true
            }else{
                if (row.STFGNM==='已审核'){
                    this.saveFlag=true
                }else{
                    this.saveFlag=false
                }   
            }
            this.addFlag='edit'
            this.poRow =JSON.parse(JSON.stringify(Object.assign(row,{IDSEQ:row.IDSEQ})))
            this.rowIdseq=row.IDSEQ
            //采购明细
            this.getDtlList(row.IDSEQ)
            this.addWin=true      
        },
        //保存记录 
        saveRec(){
            //发起保存请求   
            this.$refs['dtlform'].validate((valid) => {
                if (valid) {
                    let v_sum=0
                    // for(let k=0 ;k<this.dtlData.length;k++){
                    //     v_sum=v_sum+ Number(this.dtlData[k]['AMT'])
                    // }  
                    this.$axios({
                        method: 'post',
                        url:this.$store.state.baseUrl+'sysprivs/formOneSave?username='+this.v_username+'&password='+this.v_password,
                        data: {p_json:JSON.stringify( this.poRow ), p_table:'po_ord',frmid:'35'},
                    }).then(res=>{      
                        if(res.data.result ==='OK')  {  
                            let idseq_ =res.data.idseq
                            //1、采购明细保存：批量保存   
                            this.$axios({   
                                method: 'post',
                                url:this.$store.state.baseUrl+'sysprivs/batchBodySave?username='+this.v_username +'&password='+this.v_password,
                                data: {p_json:JSON.stringify(this.dtlData), p_table:'po_orddtl',p_idseq:idseq_ },
                            }).then(res=>{
                                if (res.data.code!=='200'){
                                    this.promptWin=true
                                    this.prompt=res.data.result
                                }
                            })
                            //采购明细删除
                            for (let k=0;k<this.delDtlArr.length;k++) {
                                this.$axios({   
                                    method: 'post',
                                    url:this.$store.state.baseUrl+'sysprivs/delTableRec?username='+this.v_username +'&password='+this.v_password,
                                    data: {p_key:'idseq',p_value:this.delDtlArr[k].IDSEQ,p_key2:'lstseq',p_value2:this.delDtlArr[k].LSTSEQ, p_table:'PO_orddtl'},
                                })
                            } 
        
                            this.addWin =false   
                            this.stfgChange()
                        }else{
                            this.promptWin=true
                            this.prompt=res.data.result
                        }        
                    }) 
                }
            })
        },
        //新增
        addRec(){
            this.addWin=true
            this.poRow={ORDDTTM:getDate('day'),'IDSEQ':''}
        },
        //表行记录css
        tableRowClass({row, rowIndex}) {
            if (row.STFG  === 'Y') {
                return 'success-row';
            } else if (row.STFG ==='X') {
                return 'cancel-row';
            }
            return '';
        },
        getRowKey(row){
            return row.IDSEQ
        },
        getDtlKey(row){
            if (row.IDSEQ && row.LSTSEQ)
            return row.IDSEQ+'-'+row.LSTSEQ
        },
        cfmSure(){
            let val =this.v_stfg
            let v_comm=''
            if (val==='Y'){
                v_comm='审核'
            }else if (val==='N'){
                v_comm='弃审'
            }else if (val==='A'){
                v_comm='发起审核'
            }else if (val==='U'){
                v_comm='驳回'
            }else if (val==='X'){
                v_comm='删除'
            }
            this.$confirm('你确定要'+v_comm+'此单据吗?','提示',{
                confirmButtonText: '确定',
                cancelButtonText:'取消',
                customClass:'messageBox',
                showClose:false,
                type:'info',
            }).then(()=>{
                var vm=this;
                let cond_='' ,v_date=getDate('full')
                if (val=='Y'){
                    cond_=' set stfg=\'Y\',chkdttm=\''+v_date+'\',chkuser=\''+this.v_usernm+'\''
                }else{
                    cond_=' set stfg=\''+val+'\',chkdttm=null ,chkuser=null '
                }
                this.$axios({
                    method: 'post',
                    url:vm.$store.state.baseUrl+'user/updateRec?username='+vm.v_username+'&password='+vm.v_password,
                    data: {p_key:'idseq',p_value:this.v_pkseq,p_table:'po_ord',p_cond:cond_},
                }).then(res=>{      
                    if(res.data.code =='200')  {   
                        if (val=='X'){
                            this.poData.splice(this.v_index,1)
                        }else{        
                            if (val=='Y'){
                                this.poData.splice(this.v_index,1,Object.assign(this.v_row,{STFGNM:v_comm ,STFG:val,CHKUSER:this.v_usernm,CHKDTTM:v_date}) )
                            }else{
                                this.poData.splice(this.v_index,1,Object.assign(this.v_row,{STFGNM:v_comm ,STFG:val,CHKUSER:'',CHKDTTM:''}) )
                            }
                        }
                        //保存日志
                        let v_obj ={IDSEQ:this.v_pkseq,USERNO:this.v_username,USERNM:this.v_usernm,RMK:this.v_rmk,DTTM:getDate('full'),ACTION:val}
                        this.$axios({
                            method: 'post',
                            url:vm.$store.state.baseUrl+'sysprivs/tableBodySave?username='+ this.v_username+'&password='+ this.v_password,
                            data: {p_json:JSON.stringify(v_obj), p_table:'po_rmk',p_idseq:'',p_user:vm.v_username},
                        }).then(res=>{
                            this.cfmWin =false
                        }) 
                    }else{
                        this.promptWin =true
                        this.prompt=res.data.result
                    }        
                })
            }).catch(()=>{})
        },
        //状态变更
        stfgRec(row,val,index){
            this.v_stfg =val
            this.v_pkseq =row['IDSEQ']
            this.v_index =index 
            this.v_row =row
            this.cfmWin =true
            this.v_oper =this.v_usernm 
            this.v_dttm=getDate('full')
        },
        //状态查询
        stfgChange(){
            let cond_=''
            if (this.selparam){
                if (this.oper==='like'){
                    cond_=cond_+' and upper('+this.selparam+') like upper(\'%'+this.txtparam+'%\')'
                }else{
                    cond_=cond_+' and upper('+this.selparam+')'+this.oper+'upper(\''+this.txtparam+'\')'
                }
            }
            if (this.selparam2){
                if (this.oper2==='like'){
                    cond_=cond_+' and upper('+this.selparam2+') like upper(\'%'+this.txtparam2+'%\')'
                }else{
                    cond_=cond_+' and upper('+this.selparam2+')'+this.oper2+'upper(\''+this.txtparam2+'\')'
                }
            }
            if (this.stfgSet!=='ALL'){
               cond_=cond_+' and  stfg=\''+this.stfgSet+'\''
            }
            this.getPoList(cond_)
        }, 
        goNext(){
            if (this.goPage){
                this.changePage(this.goPage)
                this.current =Number(this.goPage)
            }else{
                this.$alert('请输入跳至页数', '提示', {
                    confirmButtonText: '确定',
                    showClose:false,
                    type:'info'
                })
            }
        },
        //表格页面导航
        changePage(index){
            var _start = ( index - 1 ) * this.rowSize;
            var _end = index * this.rowSize;
            this.poData = this.allList.slice(_start,_end);
        },
        //初始化查询页条数
        initPage(){
            //产品明细
            this.dataCount =this.allList.length //总条数
            // 初始化显示，小于每页显示条数，全显，大于每页显示条数，取前每页条数显示
            if (this.allList.length < this.rowSize){
                this.poData = this.allList          
            }else{
                this.poData =this.allList.slice(0,this.rowSize) //返回选定元素的子数组，不包含结尾元素
            }
             
        },
 
        //采购单 
        getPoList(cond){
            let vm =this
            getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_po_ord','','','','','','',cond ).then((res) => { 
                this.allList =res.data 
                this.initPage() 
                this.$nextTick(() => { //有固定列时 查询后重新布局
                    if(this.$refs.refpo.doLayout){
                        this.$refs.refpo.doLayout();
                    }
                })
            })
        },
        //获取表列头字段  v-enter-number
        getTabCol(){
            var vm=this
            getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','35','fty','HEAD','','',' order by sortby,lstseq' ).then((res) => {
                for (let k=0; k<res.data.length; k++){
                    vm.poCol.push({ //向数组的开头 添加序列号
                        title: res.data[k].title,
                        key: res.data[k].key,
                        align: res.data[k].align,
                        width: res.data[k].width,  
                        thide: res.data[k].THIDE, 
                        fhide: res.data[k].FHIDE,                                               
                        disabled: res.data[k].DISABLED, 
                        edcss: res.data[k].EDCSS, 
                        dotnum: res.data[k].DOTNUM, 
                    })
                }
            })
            getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','35','fty','HEAD4','','',' order by sortby,lstseq' ).then((res) => {
                for (let k=0; k<res.data.length; k++){
                    vm.cfmCol.push({ //向数组的开头 添加序列号
                        title: res.data[k].title,
                        key: res.data[k].key,
                        align: res.data[k].align,
                        width: res.data[k].width,  
                        thide: res.data[k].THIDE, 
                        fhide: res.data[k].FHIDE,                                               
                        disabled: res.data[k].DISABLED, 
                        edcss: res.data[k].EDCSS, 
                        dotnum: res.data[k].DOTNUM, 
                    })
                }
            })
            getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','35','fty','HEAD2','','',' order by sortby,lstseq' ).then((res) => {
                for (let k=0; k<res.data.length; k++){
                    vm.dtlCol.push({ //向数组的开头 添加序列号
                        title: res.data[k].title,
                        key: res.data[k].key,
                        align: res.data[k].align,
                        width: res.data[k].width,  
                        thide: res.data[k].THIDE, 
                        fhide: res.data[k].FHIDE,                                               
                        disabled: res.data[k].DISABLED, 
                        edcss: res.data[k].EDCSS, 
                        dotnum: res.data[k].DOTNUM, 
                    })
                }
                this.dtlArr =this.dtlCol.filter(item=> item.thide=='N')
            })
 
        },
    }
}
</script>
<style scoped lang="less">
    ::v-deep .el-table {
        th {
            padding: 0px ;
        }
        thead{
            height: 16px !important;
            color:rgb(27, 11, 11);
            font-size:12px;
        }
        td {
            padding: 3px ;
            font-size:11px;
        }   
    }
    .item-width  {
        width: 32%;
        color:#409EFF;
    }
    .item-one  {
        width: 98%;
        color:#409EFF;
    }
    .pub_dialog {
        display: flex;
        justify-content: center;
        align-items: Center;
        overflow: hidden;
        .el-dialog {
            margin: 0 auto !important;
            height: 90%;
            overflow: hidden;
            .el-dialog__body {
                position: absolute;
                left: 0;
                top: 54px;
                bottom: 0;
                right: 0;
                padding: 0;
                z-index: 1;
                overflow: hidden;
                overflow-y: auto;
            }
        }
    }
</style>
<style lang="less">
 
    .el-dialog__body{
        padding: 10px;
    }
    .el-dialog__header{
        padding: 0px;
    }
    .el-dialog__footer{
        padding: 5px;
        border-top:1px solid #ccc;
    } 
    .el-dialog__headerbtn{font-size: 16px; background:white;top:5px !important;}
    //.el-dialog__headerbtn i { background:white;font-size: 20px;  }
 
    .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after{
        border-color :#3d77eb;
    }
    .el-checkbox__input.is-disabled+span.el-checkbox__label{
        color:#3d77eb
    }
 
</style>